<template>
  <div class='row'>
    <div class='col-lg-12'>
      <div class='card card-top card-top-primary'>
        <div class='card-header d-flex justify-content-between align-items-center'>
          <vb-headers-card-header :data="{ title: 'Slider Yönetimi' }" />
          <router-link to='/yeni-slider-ekle' type='button' class='btn btn-primary btn-with-addon text-nowrap'>
              <span class='btn-addon'>
                <i class='btn-addon-icon fe fe-plus-circle'></i>
              </span>
            Yeni Slider Ekle
          </router-link>
        </div>
        <div class='card-body'>
          <div class='table-responsive text-nowrap'>
            <a-table :columns='columns'
                     :data-source='CarouselImageTable'
                     :pagination=false
                     :row-key='(record) => record.id'
                     :loading='loading'
                     :locale='{emptyText: "Resim Yok"}'
            >
              <template #id='{ record }'>
                <span class='font-weight-bold'>{{ record.id }}</span>
              </template>
              <template #image='{ record }'>
                <div class='vb__utils__avatar'>
                  <a-image
                    :src='record.image.image1x'
                    alt='User'
                    fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                  />
                </div>
              </template>
              <template #link='{ record }'>
                <span :class='!record.link ? "font-italic text-gray-3" : ""'>{{ record.link ? `${record.link}` : 'Belirtilmemiş' }}</span>
              </template>
              <template #order='{record}'>
                <span>{{ record.order }}</span>
              </template>
              <template #operation='{ record }'>
                <a-button
                  class='btn btn-primary mr-1'
                  @click='showModal(record.id)'
                  :loading='modalLoading === record.id'
                  style='width: 46px'
                >
                  <i class='fe fe-settings text-white' v-if='modalLoading !== record.id' />
                </a-button>

                <a-popconfirm
                  title='Sliderı Silmek İstediğinize Emin Misiniz?'
                  ok-text='Evet'
                  cancel-text='Hayır'
                  @confirm='confirmDelete(record.id)'
                >
                  <a-button class='btn btn-danger'>
                    <i class='fe fe-trash text white' />
                  </a-button>
                </a-popconfirm>
              </template>
            </a-table>
          </div>
          <div class='clearfix mt-2'>
            <a-pagination
              class='float-right'
              @change='loadCarouselImages'
              v-model:page-size='pagination.recordsPerPage'
              :default-page-size='pagination.recordsPerPage'
              v-model:current='pagination.currentPage'
              :default-current='pagination.currentPage'
              :total='pagination.totalRecords'
              :show-total='total => `Toplam ${total} kayıt`'
            />
          </div>
        </div>
      </div>
    </div>

    <a-modal v-model:visible='visible' centered title='Slider Resmi Düzenle'>
      <template #footer>
        <a-button key='back' @click='visible = false'>Kapat</a-button>
      </template>

      <a-form
        label-align='left'
        layout='vertical'
      >
        <a-image
          :src='currentImage'
          alt='CarouselImage'
          fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
          style='width: 100%; height: auto'
        />

        <a-form-item label='Link' name='order' class='mt-4'>
          <a-input v-model:value='currentLink' />

          <a-button v-if='currentCarouselImage && currentLink !== currentCarouselImage.link' @click='updateLink' :loading='updateLinkLoading'
                    class='btn btn-success mt-2'>
            Güncelle
          </a-button>
        </a-form-item>

        <a-form-item label='Sıralama' v-maska='"###"' name='order'>
          <a-input v-model:value='currentOrder' />

          <a-button v-if='currentCarouselImage && currentOrder.toString() !== currentCarouselImage.order.toString()' @click='updateOrder' :loading='updateOrderLoading'
                    class='btn btn-success mt-2'>
            Güncelle
          </a-button>
        </a-form-item>

      </a-form>
    </a-modal>

  </div>
</template>

<script>
import { CarouselAdministration } from '../../services/api-service'
import moment from 'moment'
import trLocale from 'moment/locale/tr'
import { notification } from 'ant-design-vue'

moment.updateLocale('tr', trLocale)

export default {
  name: 'SliderYonetimi',
  data() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        slots: { customRender: 'id' },
      },
      {
        title: 'Resim',
        dataIndex: 'image',
        slots: { customRender: 'image' },
      },
      {
        title: 'Link',
        dataIndex: 'link',
        slots: { customRender: 'link' },
      },
      {
        title: 'Sıralama',
        dataIndex: 'order',
        sorter: (a, b) => parseInt(a.order) - parseInt(b.order),
        defaultSortOrder: 'ascend',
        slots: { customRender: 'order' },
      },
      {
        title: 'Yönet',
        dataIndex: 'operation',
        className: 'text-right',
        slots: { customRender: 'operation' },
      },
    ]

    return {
      updateOrderLoading: false,
      updateLinkLoading: false,
      visible: false,
      modalLoading: null,
      CarouselImageTable: [],
      columns,
      loading: false,
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
      currentCarouselImage: undefined,
      currentImage: '',
      currentOrder: '',
      currentLink: '',
    }
  },
  created: function() {
    this.moment = moment
  },
  mounted() {
    this.loadCarouselImages()
  },
  methods: {
    async loadCarouselImages() {
      this.loading = true

      try {
        const { data } = await CarouselAdministration.listCarouselImagesForAdministration(this.pagination.currentPage)
        this.CarouselImageTable = data.carouselImages
        this.pagination = data.pagination
      } catch {
      }

      this.loading = false
    },
    async confirmDelete(id) {
      this.loading = true

      try {
        await CarouselAdministration.deleteCarouselImageForAdministration({ imageId: id })
        this.CarouselImageTable = this.CarouselImageTable.filter(x => x.id !== id)

      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
    async updateLink() {
      this.updateLinkLoading = true
      try {
        await CarouselAdministration.updateCarouselImageLinkForAdministration({ imageId: this.currentCarouselImage.id, newLink: this.currentLink })
        this.CarouselImageTable = this.CarouselImageTable.map(x => {
          let data = x
          if (data.id === this.currentCarouselImage.id) {
            data.link = this.currentLink
          }
          return data
        })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Link Başarıyla Güncellendi!',
        })

      } catch (e) {
        console.log(e)
      }
      this.updateLinkLoading = false
    },
    async updateOrder() {
      this.updateOrderLoading = true
      try {
        await CarouselAdministration.updateCarouselImageOrderForAdministration({ imageId: this.currentCarouselImage.id, order: parseInt(this.currentOrder) })
        this.CarouselImageTable = this.CarouselImageTable.map(x => {
          let data = x
          if (data.id === this.currentCarouselImage.id) {
            data.order = this.currentOrder
          }
          return data
        })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Sıralama Başarıyla Güncellendi!',
        })

      } catch (e) {
        console.log(e)
      }
      this.updateOrderLoading = false
    },
    async showModal(id) {
      this.modalLoading = id
      this.currentCarouselImage = this.CarouselImageTable.find(x => x.id === id)

      if (this.currentCarouselImage) {
        this.currentImage = this.currentCarouselImage.image.image4x
        this.currentLink = this.currentCarouselImage.link
        this.currentOrder = this.currentCarouselImage.order
      }

      this.modalLoading = null
      this.visible = true
    },
  },
}
</script>
